import React from "react"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import styled from "styled-components"

import "./index.scss"

// import { Link } from "gatsby"
import { Link as GatsbyLink } from "gatsby"
const StyledGatsbyLink = styled(GatsbyLink)`
  color: aqua;
  text-decoration: none;
  position: relative;
  transition: background-color 0.4s ease;
  background-color: none;

  &:after {
    content: "繼續閱讀";
    font-size: 1em;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: border-bottom 0.2s ease-out;
    border-bottom: none;
  }

  &:hover {
    /* font-size: 1.5em; */
    /* line-height: 2em; */
    background-color: #ece9f33d;
    &:after {
      border-bottom: 5px solid #0801102e;
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div className="all-blog-post-wrapper">
      <ul>
        {data.allWordpressPost.edges.map((post, index) => {
          return (
            <li
              key={post.node.id}
              style={{ padding: "20px 0", borderBottom: "1px solid #ccc" }}
            >
              <StyledGatsbyLink
                to={`/post/${post.node.slug}`}
                style={{
                  display: "flex",
                  color: "black",
                  textDecoration: "none",
                }}
              >
                {/* <Img
                  sizes={post.node.acf.feat_img.localFile.childImageSharp.sizes}
                  alt={post.node.title}
                  style={{ width: "25%", marginRight: 20 }}
                /> */}
  
                <div style={{ width: "75%" }}>
                  <h3
                    dangerouslySetInnerHTML={{ __html: post.node.title }}
                    style={{ marginBottom: 0 }}
                  ></h3>
                  <p style={{ margin: 0, color: "grey" }}>
                    Written by {post.node.author.name} on {post.node.date}
                  </p>
                  <dir
                  className="blog-post-excerpt"
                    dangerouslySetInnerHTML={{ __html: post.node.excerpt }}
                  ></dir>
                </div>
              </StyledGatsbyLink>
            </li>
          )
        })}
      </ul>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          title
          excerpt
          slug
          author {
            name
          }
          date(formatString: "MMMM DD, YYYY")
          # acf {
          #   feat_img {
          #     localFile {
          #       childImageSharp {
          #         sizes(maxWidth: 600) {
          #           ...GatsbyImageSharpSizes
          #         }
          #       }
          #     }
          #   }
          # }
        }
      }
    }
  }
`
